import { template as template_01139456db8a4f0785689dde2bb11cc9 } from "@ember/template-compiler";
const FKText = template_01139456db8a4f0785689dde2bb11cc9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
