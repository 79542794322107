import { template as template_a123876b27c14f89aeb2c193d1669fcf } from "@ember/template-compiler";
const FKLabel = template_a123876b27c14f89aeb2c193d1669fcf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
